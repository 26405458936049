
import {Link} from "react-router-dom";
import NormContent from "../components/content/singleelements/NormContent";

export default function Impressum(){

    return(
        <NormContent maxWidth={"800px"}>        <h1>Impressum</h1>
            <p><strong> Hüsken GaLaBau GmbH & Co. KG</strong><br/>
                Geschäftsführer: Markus Hüsken<br/>
                Bühnert 9<br/>
                46286 Dorsten
            </p>
            <p>

    Amtsgericht Gelsenkirchen, HRA 5509
        </p>
        <p>

    Persönlich haftende Gesellschafterin:<br/> Hüsken GaLaBau Verwaltungs GmbH, Amtsgericht Gelsenkirchen, HRB 13978
        </p>

            <h3>Kontakt:</h3>
            <p>

                Telefon: 02369 - 20 89 88 8<br/>
                Telefax: 02369 - 20 89 71<br/>
                E-Mail: info@huesken-landschaftsbau.de
            </p>

            <h3>Umsatzsteuer-ID:</h3>
            <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br/>
                DE310922427</p>

            <h3>Angaben zur Berufshaftpflichtversicherung:</h3>
            <p>
                <strong>Name und Sitz der Gesellschaft:</strong><br/>
                Westfälische Provinzial-Versicherung<br/>
                Provinzial-Allee 1<br/>
                48159 Münster
            </p><p>
                <strong>Geltungsraum der Versicherung: EU</strong>
        </p>
            <h3>Umsetzung</h3>

            <p>Technische Umsetzung:<br/>
                <Link to={"https://www.mike-bembenek.de"} target={"_blank"}>https://mike-bembenek.de</Link>
            </p>


           <h2>EU-STREITSCHLICHTUNG</h2>
            <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr/.
            Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </p>
            <h2>VERBRAUCHER­STREIT­BEILEGUNG/UNIVERSAL­SCHLICHTUNGS­STELLE</h2>
            <p>
            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
            </p>
            </NormContent>
    )
}