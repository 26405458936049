




export default function NormContent({maxWidth="1024px",children,margintop="0px", magintbottom="0px"}){

    return(

        <section style={{
            maxWidth: maxWidth,
            marginInline: 'auto',
            height: 'auto',
            marginBottom: magintbottom,
            marginTop: margintop

        }} className={"normcontent"}>
            {children}
        </section>
    )
}