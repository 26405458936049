import React from "react";
import styles from './css/header.module.css'
import Logo from '../../assets/img/logo.png'
import {Link} from 'react-router-dom';
import {PhoneCall as Telicon, Mail as MailIcon} from "react-feather";
import InlayElement from "../content/singleelements/InlayElement";
import GridColumns from "../content/gridlayouts/GridColumns";
import GridInnerElement from "../content/gridlayouts/GridInnerElement";

function Header({areaName}){

    return(
        <header className={styles.header}>
      <InlayElement maxWidth={"1024px"}>
     <GridInnerElement gap={"unset"} amountCols={2}>
          <div className={styles.logo}><Link to={"/"}><img src={Logo} alt={'Hüsken GalaBau'}/></Link></div>
        <div className={styles.contact}>
                        <a href={'tel:+4923692089888'}><span><Telicon/></span>02369 - 20 89 888</a>
                        <a href={'mailto:info@huesken-landschaftsbau.de'}><span><MailIcon/></span>info@huesken-landschaftsbau.de</a>
        </div>
     </GridInnerElement>
      </InlayElement>
        </header>

    )
}
export default Header;