
import TextElement from "../components/content/singleelements/TextElement";
import InlayElement from "../components/content/singleelements/InlayElement";
import ImageElement from "../components/content/singleelements/ImageElement";
import TeaserImage from "../assets/bilder/home-start-teaser.jpg";
import JobTeaser from "../components/content/singleelements/JobTeaser";
import MinHeighElement from "../components/content/singleelements/MinHeighElement";
export default function Home(){

    return(
        <>

         <ImageElement imgSrc={TeaserImage} imagecontent={"Komm in unser Team!"} isBackgroundImage={true}/>



            <MinHeighElement minHeight={"500px"}>

<InlayElement maxWidth={'600px'} magintbottom={"0px"}>
    <TextElement fontSize={"24px"} lineHeight={"36px"} fontWeight={"300"} color={`#333333`} marginY={"100px"} alignment={"center"}>
        Wir suchen ab sofort <strong>Vorarbeiter und Facharbeiter</strong> im Bereich Gartenlandschaftsbau.
    </TextElement>
</InlayElement>
<InlayElement>
        <JobTeaser/>
</InlayElement>
        </MinHeighElement>
        </>

    )
}