import React from "react";
import styles from '../css/formular.module.css'
import {Link} from "react-router-dom";

function Jobformular({formData, setFormData,handleForm, showButton, setShowButton, handleButtom}){

    return(
        <section className={styles.formwrapper}>
            <form onSubmit={handleForm}>


                <label htmlFor={"name"}>Name, Vorname <span class={styles.frmfieldrequired}>*</span></label>
                <input
                    id={"name"}
                    type={"text"}
                    value={formData.name}
                    onChange={(e) => setFormData({...formData, name: e.target.value})}
                    placeholder={"Name, Vorname"}
                    required
                />


                <label htmlFor={"email"}>E-Mail Adresse <span class={styles.frmfieldrequired}>*</span></label>
                <input
                    type={"email"}
                    value={formData.email}
                    onChange={(e) => setFormData({...formData, email: e.target.value})}
                    placeholder={"Ihre E-Mailadresse"}
                    required
                />

                <label htmlFor={"telnumber"}>Telefonnummer <span class={styles.frmfieldrequired}>*</span></label>
                <input
                    type={"text"}
                    value={formData.telnumber}
                    onChange={(e) => setFormData({...formData, telnumber: e.target.value})}
                    placeholder={"Ihre Telefonnummer"}
                    required
                />

                <label htmlFor={"message"}>Nachricht <span class={styles.frmfieldrequired}>*</span></label>
                <textarea
                    onChange={(e) => setFormData({...formData, message: e.target.value})}
                    placeholder={"Ihre Nachricht"}
                    required
                ></textarea>
                <section className={styles.disclaimer}>

                    <input
                        type="checkbox"
                        className={styles.checkdse}
                        onClick={handleButtom}
                    />
                    <span className={styles.dsetext}>Ich akzeptiere die <Link to="/datenschutz"
                                                                                 target="_blank">Datenschutzerklärung</Link> </span>


                <button
                    className={styles.savebutton}
                    disabled={!showButton ? "disabled" : ''}
                    style={
                        !showButton ? {opacity: 0.5} : {opacity: 1}

                    }


                >


                    Bewerbung absenden
                </button>
                </section>


</form>
</section>
)
}

export default Jobformular;

