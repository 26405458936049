import React from "react";
import styles from './css/fullsize.module.css';
import RowElement from "./RowElement";
import ColumnElement from "./ColumnElement";
import {getAllByPlaceholderText} from "@testing-library/react";
function GridInnerelement({amountCols, gap, children})

{

    return(

     <section className={styles.gridinner}
     style={{

         gridTemplateColumns: `repeat(${amountCols}, 1fr)`,
         gap: gap,
         width: '100%',
         height:'100%'

     }}
     >

         {children}
     </section>





    )
}
export default GridInnerelement;
