import React from "react";
import styles from '../css/imageelement.module.css';
function ImageElement({imgSrc, isBackgroundImage, imagecontent}){

    return(
        <>
            {isBackgroundImage ?(

            <div className={styles.backgroundimage} style={{
                backgroundImage: `url(${imgSrc})`
            }}>

                <div className={styles.bgimagecontent}><h3>{imagecontent}</h3></div>
            </div>
        ):(
            <div className={styles.normimage}>
                <figure>
                <img src={imgSrc} alt={imagecontent}/>

               <figcaption>{imagecontent}</figcaption>
                </figure>


            </div>
            )}
        </>
    )
}
export default ImageElement;