import {useParams} from "react-router-dom";
import styles from '../components/content/css/jobdetail.module.css';
import frmstyles from '../components/content/css/formular.module.css';
import axios from "axios";
import InlayElement from "../components/content/singleelements/InlayElement";
import ImageElement from "../components/content/singleelements/ImageElement";
import TeaserImage from "../assets/bilder/baustelle.jpg";
import TeaserContentImage from "../assets/bilder/bagger.jpg";
import FachImage from "../assets/bilder/drohne-hof.jpg";
import FachContentImage from "../assets/bilder/hof.jpg";
import Modal from "../components/content/singleelements/Modal";
import GridInnerElement from "../components/content/gridlayouts/GridInnerElement";
import React from "react";
import Jobformular from "../components/content/forms/Jobformular";
export default function Jobdetails(){

    const { jobname } = useParams();

    const [showModal, setShowModal] = React.useState(true);
    const [showForm, setShowForm] = React.useState(true)
    const [sendFormular, setSendFormular] = React.useState(false);
    const [showButton, setShowButton] = React.useState(false);
    const [formularData, setFormularData] = React.useState({job: jobname.toUpperCase(), name:'', email:'', telnumber:'', message:''})
    function handleModal(){
        setShowModal(!showModal);
        setShowForm(true);
        setShowButton(false);
    }   function handleButtonModal(){
        setShowModal(!showModal);
        setShowForm(!setShowForm);
    }
    function handleButton() {
        setShowButton(!showButton);
    }

    const handlForm = async(event)=>{
        event.preventDefault();
        setShowForm(false);
        const apiurl ="https://jobs.hueskenlandschaftsbau.de/lpapi/";
        const postData = {
            name: formularData.name,
            jobname: formularData.job,
            telnumber: formularData.telnumber,
            email: formularData.email,
            message: formularData.message,
           };

        try {
            const response = await axios.post(apiurl + 'sendformdata.php', postData);

            if (response.status === 200) {

            } else {
            }
        } catch (error) {
        }



    }


    React.useEffect(()=>{
        if(jobname === "vorarbeiter"){
            document.title ="Wir sucheh Vorarbeiter in Dorsten";
            document.getElementsByTagName('meta')["description"].content  ="Wir suchen Vorarbeiter für folgende Fachgebiete: Bautechnik im Bereich Privatgärten";
        }
        else{
            document.title ="Wir sucheh Facharbeiter in Dorsten";
            document.getElementsByTagName('meta')["description"].content ="Wir suchen Facharbeiter für folgende Fachgebiete: Landschaftsbau, Tiefbau und Pflasterung";
        }
        setShowForm(true);
        setShowModal(false);
    }, [jobname])



    return(
        <section className={styles.jobdetailframe}>

            {



            jobname === "vorarbeiter" ?

                (
                    <section className={styles.jobinfo}>
                        <section className={styles.jobimage}>
                            <ImageElement imgSrc={TeaserImage} imagecontent={"Vorarbeiter(m/w/d)"}
                                          isBackgroundImage={true}/>
                        </section>


                        <section className={styles.jobcontent}>
                            <InlayElement>
                                <h3>Wir suchen Vorarbeiter für folgende Fachgebiete:</h3>
                                <p><strong>Bautechnik im Bereich Privatgärten</strong></p>
                            </InlayElement>
                        </section>
                        <section className={styles.jobinfos}>
                            <InlayElement>
                                <GridInnerElement amountCols={2} gap={"25px"}>
                                    <div>
                                        <h3>Was dich bei uns erwartet:</h3>
                                        <ul>
                                            <li>Verlegung von Rollrasen, Bepflanzungen</li>
                                            <li>Gartenum- und neugestaltung</li>
                                            <li>Zaunbau</li>
                                            <li>Platz-, Wege, und Spielplatzbau</li>
                                            <li>Pflasterung und Natursteinarbeiten</li>
                                            <li>Erd- und Aushubarbeiten</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3>Was du mitbringst:</h3>
                                        <ul>
                                            <li>Du hast eine abgeschlossene Ausbildung zum Gärtner in Fachrichtung
                                                GaLaBau.
                                            </li>
                                            <li>Du hast im besten&nbsp;Fall schon Berufserfahrung.</li>
                                            <li>Du besitzt bereits alle nötigen Führerscheine.</li>
                                            <li>Pünktlichkeit, Zuverlässigkeit und Ehrlichkeit</li>
                                            <li>Du arbeitest gerne im Team und führst die Arbeiten akkurat aus</li>
                                        </ul>

                                    </div>
                                </GridInnerElement>
                            </InlayElement>

                        </section>
                        <section className={styles.jobbenefits}>
                            <InlayElement>
                                <GridInnerElement amountCols={2} gap={"25px"}>

                                    <div>
                                        <h3>Das bieten wir dir:</h3>
                                        <ul>
                                            <li>Moderner Arbeitsplatz mit umfangreichem Geräte- und Maschinenpark</li>
                                            <li>individuelle Lehrgänge und Weiterbildungsmöglichkeiten</li>
                                            <li>Vermögenswirksame Leistungen</li>
                                            <li>Betriebliche Altersvorsorge</li>
                                            <li>30 Tage Urlaub im Jahr</li>
                                            <li>Firmenhandy</li>
                                            <li>übertarifliche Bezahlung</li>
                                            <li>regelmäßige Teamgespräche</li>
                                            <li>Einbindung in die Unternehmensentwicklung</li>
                                            <li>Höchstmögliche steuerfreie monatliche Zuwendung</li>
                                            <li>Familiäre Atmosphäre</li>
                                        </ul>
                                    </div>
                                    <div className={styles.contentimage}>
                                        <ImageElement imgSrc={TeaserContentImage}/>
                                    </div>
                                </GridInnerElement>
                            </InlayElement>
                        </section>
                        <section className={styles.jobcontact}>
                            <InlayElement>

                                <GridInnerElement amountCols={2}>
                                    <h3>Interesse? <br/>Melde dich bei uns!</h3>
                                    <ul className={styles.contactlist}>
                                        <li><a
                                            href={"mailto:info@huesken-landschaftsbau.de?subject=Bewerbung als Vorarbeiter"}>Bewerben
                                            per E-Mail</a></li>
                                        <li><a onClick={handleModal}>Bewerben per Formular</a></li>


                                </ul>
                                </GridInnerElement>


                            </InlayElement>
                        </section>
                    </section>


                )
                : (
                    <section className={styles.jobinfo}>
                        <section className={styles.jobimage}>
                            <ImageElement imgSrc={FachImage} imagecontent={"Facharbeiter (m/w/d)"}
                                          isBackgroundImage={true}/>
                        </section>


                        <section className={styles.jobcontent}>
                            <InlayElement>
                                <h3>Wir suchen Facharbeiter für folgende Fachgebiete:</h3>
                                <p><strong>Landschaftsbau, Tiefbau und Pflasterung</strong></p>
                            </InlayElement>
                        </section>
                        <section className={styles.jobinfos}>
                            <InlayElement>
                                <GridInnerElement amountCols={2} gap={"25px"}>

                                    <div>
                                        <h3>Was dich bei uns erwartet:</h3>
                                        <ul>
                                            <li>Verlegung von Rollrasen, Bepflanzungen</li>
                                            <li>Baumrodung, Baum- und Strauchschnitt</li>
                                            <li>Gartenpflege, Gewerbeflächenpflege</li>
                                            <li>Gartenum- und neugestaltung</li>
                                            <li>Zaunbau</li>
                                            <li>Platz-, Wege, und Spielplatzbau</li>
                                            <li>Pflasterung und Natursteinarbeiten</li>
                                            <li>Erd- und Aushubarbeiten</li>
                                        </ul>
                                    </div>
                                    <div>
                                        <h3>Was du mitbringst:</h3>
                                        <ul>
                                            <li>Du hast eine abgeschlossene Ausbildung zum Gärtner in Fachrichtung
                                                GaLaBau.
                                            </li>
                                            <li>Du hast im besten Fall schon Berufserfahrung.</li>
                                            <li>Du besitzt bereits alle nötigen Führerscheine.</li>
                                            <li>Pünktlichkeit, Zuverlässigkeit und Ehrlichkeit</li>
                                            <li>Du arbeitest gerne im Team und bist kommunikationsfähig.</li>
                                            <li>Du denkst selbstständig mit und hast auch einen Sinn für
                                                Wirtschaftlichkeit.
                                            </li>
                                        </ul>

                                    </div>
                                </GridInnerElement>
                            </InlayElement>

                        </section>
                        <section className={styles.jobbenefits}>
                            <InlayElement>
                                <GridInnerElement amountCols={2} gap={"25px"}>

                                    <div>

                                        <h3>Das bieten wir dir:</h3>
                                        <ul>
                                            <li>Moderner Arbeitsplatz mit umfangreichem Geräte- und Maschinenpark</li>
                                            <li>individuelle Lehrgänge und Weiterbildungsmöglichkeiten</li>
                                            <li>Vermögenswirksame Leistungen</li>
                                            <li>Betriebliche Altersvorsorge</li>
                                            <li>30 Tage Urlaub im Jahr</li>
                                            <li>Firmenhandy</li>
                                            <li>übertarifliche Bezahlung</li>
                                            <li>regelmäßige Teamgespräche</li>
                                            <li>Einbindung in die Unternehmensentwicklung</li>
                                            <li>Höchstmögliche steuerfreie monatliche Zuwendung</li>
                                            <li>Familiäre Atmosphäre</li>
                                        </ul>
                                    </div>
                                    <div className={styles.contentimage}>
                                 <ImageElement imgSrc={FachContentImage}  />
                                            </div>
                                        </GridInnerElement>
                                    </InlayElement>
                                </section>
<section className={styles.jobcontact}>
    <InlayElement>

                   <GridInnerElement amountCols={2}>
                                <h3>Interesse? <br/>Melde dich bei uns!</h3>
                                <ul className={styles.contactlist}>
                                    <li><a href={"mailto:info@huesken-landschaftsbau.de?subject=Bewerbung als Facharbeiter"}>Bewerben per E-Mail</a></li>
                                    <li><a onClick={handleModal}>Bewerben per Formular</a></li>
                                </ul>
                   </GridInnerElement>


    </InlayElement>
                                </section>
                    </section>

                )}
            {
                showModal ?(
                    <Modal handleModal={handleModal} modalTitle={"Bewerbungsformular: "+ formularData.job} >

                        {showForm ?(

                        <Jobformular formData={formularData} setFormData={setFormularData} handleForm={handlForm} handleButtom={handleButton} setShowButton={setShowButton} showButton={showButton}/>
                            ):(
                            <>
                                <h3>Vielen Dank für Ihre Bewerbung</h3>
                                <p>Wir haben Ihre Daten erhalten und wir werden uns zeitnah mit Ihnnen in Verbindung
                                    setzen.</p>
                                <button
                                    className={styles.savebutton}
                                    onClick={handleButtonModal}
                                >

                                    Fenster schließen
                                </button>

                            </>)}
                    </Modal>

                ) : ('')
            }


        </section>
    )
}