import React from "react";
import styles from "./css/footer.module.css"
import InlayElement from "../content/singleelements/InlayElement";
import GridInnerElement from "../content/gridlayouts/GridInnerElement";
import Logo from '../../assets/img/logo.png'
import { Link} from "react-router-dom";

function Footer(){

    return(
        <footer id="footer">
        <InlayElement>
            <GridInnerElement amountCols={3} gap={'25px'}>
              <section className={styles.footerlogo}>
                  <Link to={"/"}><img src={Logo} alt={'Hüsken GalaBau'}/></Link>

              </section>
            <section className={styles.footeradress}>

                <p>Bühnert 9<br/>
                46286 Dorsten-Deuten
                </p>
            </section>
                <section className={styles.footernav}>
                    <ul>
                        <li><Link to={"/impressum"}>Impressum</Link></li>
                        <li><Link to={"/datenschutz"}>Datenschutz</Link></li>
                    </ul>


                </section>
            </GridInnerElement>

        </InlayElement>

        </footer>

    )
}
export default Footer;