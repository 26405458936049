import React from 'react';

export default function TextElement({

                                            content,
                                            fontSize = "1rem",
                                            lineHeight = "1.5rem",
                                            color,
                                            paddingX,
                                            paddingY,
                                            marginY,
                                            marginX,
                                            alignment="left",
                                           cursor="unset",

                                            textTransform="unset",
                                            fontWeight="300",
                                            className="",
    children
                                        }) {
    const Tag ='p';

    const style = {
        fontSize,
        lineHeight,
        color,
        paddingLeft: paddingX,
        paddingRight: paddingX,
        paddingTop: paddingY,
        paddingBottom: paddingY,
        textTransform: textTransform,
        fontWeight: fontWeight,
        marginBlock: marginY,
        marginInline: marginX,
        cursor: cursor,
        textAlign: alignment
    };

    return (
        <Tag style={style} className={className}>
            {children}
        </Tag>
    );
}
