import './assets/basiscss/main.css';
import Home  from'./pages/Home'
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import { Routes, Route } from 'react-router-dom';
import Impressum from "./pages/Impressum";
import Datenschutz from "./pages/Datenschutz";
import Jobdetails from "./pages/Jobdetails";
function LandingPage() {
  return (

   <>
   <Header/>
           <Routes>
               <Route path="/" element={<Home/>} />
               <Route path="/impressum" element={<Impressum/>} />
               <Route path="/datenschutz" element={<Datenschutz/>} />
                <Route path="/:jobname" element={<Jobdetails/>} />
           </Routes>

   <Footer/>
   </>

     );
}

export default LandingPage;
