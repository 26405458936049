import React from "react";
import {X as Close} from 'react-feather';
import styles from '../css/modal.module.css';

function Modal({modalTitle,children,handleModal,maxHeight,maxWidth}){
const closebtnRef = React.useRef();

React.useEffect(()=>{
    closebtnRef.current.focus();
},[])

    return(

        <section className={styles.modalwrapper}>

            <section className={styles.modaldialog}

            style={{
                maxHeight:maxHeight,
                maxWidth:maxWidth
            }}

            >
              <h2>{modalTitle}</h2>

                <button
                    ref={closebtnRef}
                    className={styles.btnmodalclode}
                    onClick={ handleModal }>
                    <Close/>
                </button>

                {
                    children
                }

            </section>

        </section>
    )
}
export default Modal
