
function MinHeighElement({minHeight="400px", children}){


    return(

        <section className={'minheightelement'} style={{minHeight:minHeight}}>
            {children}
        </section>
    )
}
export default MinHeighElement
