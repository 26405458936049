import GridInnerElement from "../gridlayouts/GridInnerElement";
import InlayElement from "./InlayElement";
import {Link} from "react-router-dom";

export default function JobTeaser() {

    return (
            <InlayElement margintop={'50px'} maxWidth={"600px"}>
                <GridInnerElement amountCols={2} gap={"50px"}>

                <div className={"jobitem"}>
                    <Link to={"/facharbeiter"}>Facharbeiter (m,w,d)</Link>
                </div>
    <div className={"jobitem lastjobitem"}>
                    <Link to={"/vorarbeiter"}>Vorarbeiter (m,w,d)</Link>
                </div>

                </GridInnerElement>

            </InlayElement>
    )

}